.footer {
    padding: 60px 120px;
    padding-bottom: 1rem;
    background-color: #212121;
    color: white;
    overflow: hidden;
}

@media screen and (max-width: 997px) {
    .footer {
    padding: 60px 40px;

    }
    
}

.footer-link {
    color: #FFF !important;
    padding: 2px;
    margin: 0px 0px;
    color: #FFF;
    font-family: 'Theinhardt Pan', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    text-transform: uppercase;
}

.foot1 h5 {
    margin-bottom: 10px;
    color: #FFF;
    font-family: 'Theinhardt Pan', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    text-transform: uppercase;
}

.footer-0 {
    display: none;
}

.foot12-1 {
    margin: 2rem 0rem;
}

.footer-title {
    color: #FFF;
    font-family: 'Theinhardt Pan', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    text-transform: uppercase;
    margin-bottom: 60px;
}

.foot12-11 {
    justify-content: space-between;
    padding: 0px 0px 10px 0;
    margin-bottom: 20px;
    border-bottom: 2px solid white;
}

.foot12-11 a {
    color: #FFF;
    font-family: 'Theinhardt Pan', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
    text-transform: uppercase;
}

#footer-links a {
    color: #FFF !important;
}

.foot1-0 {
    padding: 0px;
    padding-bottom: 4rem
}

.foot12-11 p {
    color: #FFF;
    font-family: 'Theinhardt Pan', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
    text-transform: uppercase;
}

.dropdown-content {
    transition: all 0.5s;
    overflow-x: hidden;
    padding-left: 30px;
}

.dropdown-content li {
    padding: 10px 0;
    border-bottom: 1px solid #FFf;
    color: #FFF;
}

.dropdown-content li a {
    color: #FFF !important;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
    text-transform: uppercase;
    word-break: break-word;
}


.foot2-0 {
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.foot a {
    color: #FFF !important;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
    text-transform: uppercase;
}

.foot14 p {
    font-family: 'TheinhardtPan-Regular', sans-serif;
    text-transform: capitalize;
    font-size: 14px;
}

@media(max-width: 768px) {
    .footer {
        display: none;
    }

    .footer-0 {
        display: block;
        background-color: #212121;
        padding: 40px 45px 20px 45px;
        color: white;
    }

    .foot2-0 {
        color: #FFF;
        text-align: center;
        font-family: 'TheinhardtPan-Regular', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-transform: uppercase;
    }

    .foot1-0 {
        padding: 0px;
        padding-bottom: 1rem;
    }

    .footer-drop {
        height: 12px;
        width: 8px;
        transition: ease 0.7s;
    }
}