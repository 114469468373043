@font-face {
  font-family: 'Saol';
  font-style: normal;
  font-weight: 400;
  src: local('Saol'), url('../../../font/saol_regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Goudy Old Style';
  font-style: normal;
  font-weight: 700;
  src: local('Goudy Old Style'), url('../../../font/Goudy\ Old\ Style\ Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: normal;
  src: local('Theinhardt Pan'), url('../../../font/theinhardt-pan.otf') format('otf');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: 300;
  src: local('Theinhardt Pan'), url('../../../font/theinhardt_pan_light.woff2') format('woff2');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: 500;
  src: local('Theinhardt Pan'), url('../../../font/theinhardt_pan_medium.woff2') format('woff2');
}


.login-page {
  padding: 80px;
  background-color: #fff9f2;
  padding-top: 160px;
}

.login-title {
  font-family: "Saol";
  font-style: italic;
  font-size: 65px;
  line-height: 71px;
  font-weight: 400;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 400;
  /* mix-blend-mode: darken; */
}

.sign-in-btn {
  background-color: #000;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 13px 20px;
  border-radius: 4px;
  color: #FFF;
  text-transform: uppercase;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 200;
  transition: all 0.5s;
}

.sign-in-btn:hover {
  border: 1px solid #000;
  color: #000;
  background-color: transparent;
}

.forget-pass {
  color: #000;
  cursor: pointer;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.sign-up-container {
  max-width: 500px;
  margin: 80px auto 0 auto;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  /* mix-blend-mode: darken; */
}

.info-sign-up {
  background: #fff9f2;
  padding: 120px 60px;
}

.radio-button {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 10px;
  position: relative;
  align-items: center;
  color: #000;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.radio-button input[type="radio"] {
  position: absolute;
  opacity: 0
}

.radio {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #ccc;
  transform: translateZ(-25px);
  transition: all 0.3s ease-in-out;
}

.radio::before {
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  background-color: #a03907;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.radio-button input[type="radio"]:checked+.radio {
  transform: translateZ(0px);
  background-color: #fff;
}

.radio-button input[type="radio"]:checked+.radio::before {
  opacity: 1;
}

.checkbox-button {
  display: flex;
  gap: 10px;
  margin: 10px;
  margin-bottom: 0 !important;
  position: relative;
  align-items: center;
  color: #000;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.checkbox-button input[type="radio"] {
  position: absolute;
  opacity: 0
}

.checkbox-button input[type="checkbox"] {
  position: absolute;
  opacity: 0
}

.checkbox {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  transform: translateZ(-25px);
  transition: all 0.3s ease-in-out;
}

.checkbox::before {
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  top: 2px;
  left: 2px;
  background-color: #a03907;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.checkbox-button input[type="radio"]:checked+.checkbox {
  transform: translateZ(0px);
  background-color: #fff;
}

.checkbox-button input[type="radio"]:checked+.checkbox::before {
  opacity: 1;
}

.checkbox-button input[type="checkbox"]:checked+.checkbox {
  transform: translateZ(0px);
  background-color: #fff;
}

.checkbox-button input[type="checkbox"]:checked+.checkbox::before {
  opacity: 1;
}

.checkbox-text em {
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 400;
}


@media(max-width: 768px) {
  .login-page {
    padding: 150px 15px 15px 15px;
    margin-top: 0;
  }

  .sign-up-container {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .info-sign-up {
    background: #fff9f2;
    padding: 20px;
  }
}