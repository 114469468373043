@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy&display=swap');

/* Disabled Text Selection */
html,
body,
* {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Disabled Printing of Website */
/* @media print {

    html,
    body {
        display: none;
    }
} */

@font-face {
    font-family: 'Theinhardt Pan';
    font-style: normal;
    font-weight: normal;
    src: local('Theinhardt Pan'), url('./font/theinhardt-pan.otf') format('otf');
}

@font-face {
    font-family: 'Theinhardt Pan';
    font-style: normal;
    font-weight: 300;
    src: local('Theinhardt Pan'), url('./font/theinhardt_pan_light.woff2') format('woff2');
}

@font-face {
    font-family: 'Theinhardt Pan';
    font-style: normal;
    font-weight: 500;
    src: local('Theinhardt Pan'), url('./font/theinhardt_pan_medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Saol';
    font-style: normal;
    font-weight: 500;
    src: local('Saol'), url('./font/saol_light_italic.woff2') format('woff2');
}

@font-face {
    font-family: 'Saol';
    font-style: normal;
    font-weight: 400;
    src: local('Saol'), url('./font/saol_regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Theinhardt Pan';
    font-style: normal;
    font-weight: 700;
    src: local('Theinhardt Pan'), url('./font/theinhardt_pan_regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Goudy Old Style';
    font-style: normal;
    font-weight: 700;
    src: local('Goudy Old Style'), url('./font/Goudy\ Old\ Style\ Bold.ttf') format('ttf');
}

* {
    font-family: 'Sorts Mill Goudy', serif;
}

body {
    overflow-x: hidden;
}

.home {
    overflow: hidden;
}

.home11 {
    position: relative;
}

.home11-img {
    filter: brightness(0.84);
}

.home11-nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    color: white;
    width: 100%;
}

.home11-nav11 {
    width: 20%;
}


.about_nav a {
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.about-nav12 p {
    color: #000000;
    font-size: 14px;
}

.about-nav131 p {
    color: #000000;
    font-size: 14px;
}

.about-nav132 img {
    filter: invert(1);
}

.about-nav133 {
    filter: invert(1);
}

.about11-nav2 {
    border: 1px solid #D9D9D9 !important;
}

.about11-nav {
    position: initial !important;
}

.home11-nav12 {
    width: 45%;
    padding-top: 10px;
}

.home11-nav13 {
    justify-content: end;
    width: 30%;
}

.home11-nav1 p,
.home11-nav1 a {
    font-size: 12px;
}

.home11-nav132 {
    border-left: 1px solid white;
    border-right: 1px solid white;
    padding-left: 15px;
    padding-right: 15px;
}

.home11-nav133 p {
    margin-right: 10px;
}

.home11-nav2 {
    padding: 1px 20px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.home11-nav22 a {
    font-size: 12px;
    margin: 0px 6px;
    padding: 1px 4px;
}

.home111 {
    position: absolute;
    padding: 0rem 6rem;
    top: 156px;
    height: 69%;
    width: 100%;
}

.home1111 {
    position: absolute;
    top: 55%;
    left: 25%;
    transform: translate(-50%, -50%);
}

.home1111 .hero-text {
    display: block;
    overflow: hidden;
    width: 100%;
}

.home1111 .hero-text i {
    color: #FFF;
    font-family: 'Goudy Old Style';
    font-size: 110px;
    display: block;
    font-style: italic;
    transform: translateY(100%);
    transition: 2s cubic-bezier(0.25, 0.1, 0.57, 0.83);
    transition-property: transform;
    transition-delay: 0.8s;
    font-weight: 400;
    line-height: normal;
}



@media(max-width: 1024px) {
    .home1111 {
        left: 40%;
    }
}

@media(max-width: 768px) {
    .home1111 .hero-text i {
        font-size: 60px;
    }

    .home1111 {
        left: 42%;
        top: 44%;
    }
}

.home11122 {
    width: 200px;
}

.home11123 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 50%;
    color: white;

}

.home11121 {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 60%;
    bottom: 6%;
    display: flex;
    align-items: center;
    width: fit-content;
}

.home11121 a {
    color: #fff;
}

.home2 {
    padding-top: 120px;
    padding-left: 40px;
    padding-right: 40px;
    background: #FFF9F2;
    margin-top: -22px;
}

.home2111 p {
    color: #000;
    font-family: 'Theinhardt Pan', serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home2111 h2 {
    color: #000;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.home212 p {
    color: #000;
    font-family: 'Theinhardt Pan';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-decoration-line: underline;
}

.home212 a {
    color: #000;
    font-family: 'Theinhardt Pan';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-decoration-line: underline;
}


@media(max-width: 1182px) {
    .home2111 h2 {
        color: #000;
        font-family: 'TheinhardtPan-Regular', sans-serif;
        font-size: 80px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media(max-width: 1024px) {
    .home2 {
        padding-top: 100px;
        padding-bottom: 3rem;
        padding-left: 20px;
        padding-right: 20px;
        background: #FFF9F2;
        margin-top: -22px;
    }

    .home2111 h2 {
        color: #000;
        font-family: 'TheinhardtPan-Regular', sans-serif;
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}


@media(max-width: 768px) {
    .home2111 p {
        color: #000;
        font-family: 'Theinhardt Pan', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .home2111 h2 {
        font-family: "Saol", sans-serif;
        font-size: 48px !important;
        font-style: normal;
        font-weight: 500;
        margin-top: 16px;
        line-height: 43px;
    }

    .home212 p {
        color: #000;
        font-family: 'Theinhardt Pan';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-decoration-line: underline;
    }

    .home212 a {
        color: #000;
        font-family: 'Theinhardt Pan';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-decoration-line: underline;
    }
}

.home2112 p {
    font-size: 15px;
}

.home212 img {
    filter: invert(1);
    margin-right: 10px;
    width: 10px;
}

.home221 {
    border: 1px solid rgba(0, 0, 0, 0.153);
    margin: 10px;
    height: 23rem;
}

.home2211 {
    height: 83%;
}

.home2212 {
    padding: 10px;
}

.home2212 h5 {
    font-size: 18px;
    font-weight: 600;
}

.home2212 p {
    font-size: 14px;
    margin-top: 0px;
}

/* .home2211 img{
    width: 100px;
    height: 100px;
} */


.home312 {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: #FFF;
    position: absolute;
}

.home312 h2 {
    color: #FFF;
    font-family: 'Goudy Old Style';
    font-size: 97px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.home312 p {
    color: #FFF9F2;
    font-family: 'Theinhardt Pan';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}

.home4 {
    background-color: #FFF9F2;
    padding-top: calc(20px * 10);
    padding-left: 40px;
    padding-right: 40px;
}

.home211 {
    overflow: hidden;
}

.home41 {
    overflow: hidden;
    /* width: 30%; */
    width: 45%;
}

.home42 {
    width: fit-content;
    margin-right: 10rem;
}

.home41 h2 {
    color: #000;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* .home41 h2 { color: #000; font-family: 'Theinhardt Pan', sans-serif; font-size: 100px; font-style: normal; font-weight: 600; line-height: normal; } */
.home41 p {
    margin-top: 3rem;
    color: #000;
    font-family: 'Theinhardt Pan', sans-serif;
    line-height: 1.1;
    font-size: 34px;
    font-style: normal;
    font-weight: 300;
    text-transform: uppercase;
}

.home42 {
    position: relative;
}

.home422 {
    bottom: 100px;
    right: -80px;
    position: absolute;
}

@media(max-width: 1024px) {
    .home42 {
        display: none;
        position: unset;
    }

    .home41 {
        width: 100%;
    }
}

@media(max-width: 768px) {
    .home2111 p {
        color: #000;
        font-family: 'Theinhardt Pan', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .home41 h2 {
        font-family: "Saol", sans-serif;
        font-size: 48px !important;
        font-style: normal;
        font-weight: 500;
        margin-top: 16px;
        line-height: 43px;
    }

    .home41 p {
        margin-top: 40px;
        color: #000;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}

.home5 {
    padding: 4rem;
    background-color: #FFF9F2;
}

.home51 {
    display: flex;
    height: 100%;
    width: 50%;
    margin-right: 15px;
}

.home52 {}

.home511 {
    margin-right: 15px;
}

.home512 {
    margin-left: 15px;
}

.home51111 {
    padding: 30px;
    bottom: 0;
    color: white;
}

.home51111 h5 {
    color: #FFF;
    font-family: 'Goudy Old Style';
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.home51111 p {
    color: #FFF;
    font-family: 'Goudy Old Style';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home51 img {
    filter: brightness(0.7);
    width: 100%;
    height: 100%;
}

.home52 img {
    filter: brightness(0.9);
    height: 100%;
}

.home521 {
    bottom: 20px;
    left: 30px;
    color: white;
}

.home521 h5 {
    font-size: 16px;
    font-weight: 600;
}

.home521 p {
    font-size: 14px;
}

.home5111 {
    height: 47.7%;
    overflow: hidden;
    margin-bottom: 22px;
}

.home511 {
    width: 100%;
}

.home512 {
    width: 100%;
}

.home611 {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    color: white;
    z-index: 10;
}

.home611 h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Saol';
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home611 p {

    color: #FFF;
    text-align: center;
    font-family: 'Theinhardt Pan';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
}

.home611 a {
    display: block;
    color: #FFF !important;
    text-align: center;
    font-family: 'Theinhardt Pan';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
}

.home71 {
    overflow: hidden;
    width: 45%;
}

.home72 {
    width: fit-content;
    margin-right: 0rem;
}

.home7 {
    padding: 4rem;
}

.home71 h2 {
    color: #000;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.home71 p {
    color: #000;
    font-family: 'Theinhardt Pan', sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-transform: uppercase;
    margin-top: 2rem;
}

.home8 {
    padding: 4rem;
    border-top: 1px solid #D9D9D9;
    background-color: #FFF9F2;
}

.shop-content-text p,
.shop-content-text p span strong {
    font-family: 'Roboto', sans-serif;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.shop-content-text>strong {
    margin-bottom: 1.5rem;
}

.shop-content-text strong {
    font-family: 'Roboto', sans-serif;
}

.shop-content-text {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    gap: 2rem;

    color: #000;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.6;
}

.shop-content-title {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;


    p {
        font-family: 'Roboto', sans-serif;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        line-height: 1.6;

        strong {
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
            line-height: 1.6;
        }

        ins {
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
            line-height: 1.6;
        }
    }
}



@media(max-width: 1024px) {
    .home71 {
        width: 100%;
    }

    .home72 {
        display: none;
    }

    .home1111 .hero-text i {
        font-size: 100px;
    }

    .home1111 {
        left: 35%;
    }

}

@media(max-width: 821px) {
    .home1111 .hero-text i {
        font-size: 92px;
    }

    .home1111 {
        left: 35%;
    }

    .home5111 {
        height: 47%;
        overflow: hidden;
        margin-bottom: 22px;
    }
}

@media(max-width: 769px) {
    .home71 h2 {
        font-family: "Saol", sans-serif;
        font-size: 48px !important;
        font-style: normal;
        font-weight: 500;
        margin-top: 16px;
        line-height: 43px;
    }

    .home71 p {
        font-size: 21px !important;
    }

    .home1111 .hero-text i {
        font-size: 85px;
    }

    .home1111 {
        left: 35%;
        top: 52%;
    }
}

@media(max-width: 769px) {
    .home1111 .hero-text i {
        font-size: 90px;
    }

    .home1111 {
        left: 35%;
        top: 50%;
    }
}

@media(max-width: 497px) {
    .home1111 .hero-text i {
        font-size: 70px;
    }

    .home1111 {
        left: 45%;
        top: 46%;
    }
}

@media(max-width: 415px) {
    .home1111 .hero-text i {
        font-size: 65px;
    }

    .home1111 {
        left: 46%;
        top: 46%;
    }
}

.home811 {
    width: 15%;
}

.home811 h5 {
    font-weight: 600;
    font-size: 15px;
    margin-top: 20px;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    margin-bottom: 5px;
}

.home811 p {
    font-size: 14px;
}

.home9 {
    /* background: url(/public/images/p23.png) no-repeat center center/cover; */
    height: 29rem;
    width: 100%;
    color: white;
    /* padding: 3rem 8rem; */
    /* padding-top: 0rem; */
}

.home91 .title {
    padding-top: 3rem;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-size: 14px;
}

.home91 .text {
    padding-top: 3rem;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-size: 14px;
}

.home91 .send-input {
    font-size: 135px;
    font-family: "Saol";
    font-weight: 400;
    background-color: transparent;
    color: #fff;
    outline: none;
    border: none;
    border-bottom: 1px solid #fff;
}

.home91 .send-input[type='text']:focus {
    --tw-ring-color: transparent;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #FFF;
}

.home91 h1 {
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid white;
    color: #FFF;
    font-family: 'Goudy Old Style';
    font-size: 112px;
    font-style: normal;
    font-weight: 500;
    line-height: 114%;
    /* 127.68px */
    letter-spacing: 2.24px;
}

.home-101 {
    width: 70%;
    background: rgb(158, 102, 56);
    padding: 4rem;
}

.home-102 {
    width: 30%;
}

.home-10111 {
    width: 50%;
    color: white;
}

.home-10111 p {
    font-size: 14px;
}

.home-10111 h1 {
    color: #fff;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.home91 .send-input::placeholder {
    color: #fff;
}

@media(max-width: 768px) {
    .home91 .title {
        padding: 0;
        font-size: 37px;
        font-weight: 400;
        text-transform: uppercase;
        font-family: "Saol", sans-serif;
        font-style: italic;
    }

    .home91 .send-input {
        margin-top: 100px;
        padding: 10px;
        font-size: 40px;
        width: 100%;
        background-color: rgba(255, 255, 255, .3);
    }

    .home91 {
        overflow: hidden;
    }

    .home91 .text {
        padding-top: 40px;
    }
}

.home-10112 {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: hidden;
}

.home-10112 img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    background-position: center;
    transform: scale(1);
    transition: all 0.8s;
}

.home-10112:hover img {
    transform: scale(1.3);
}

.home-1012 img {
    /* z-index: -1;
    position: relative; */
    filter: brightness(0.7);
    height: 639px;
    width: 359px;
}

.home-1012 b {
    bottom: 80px;
    width: 247px;
    color: white;
    left: 0;
    right: 0;
    width: fit-content;
    margin: auto;
    text-align: center;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    letter-spacing: 0.54px;
}

.home-1012 {
    width: fit-content;
}

.home-10 {
    height: auto;
    overflow: hidden;
    background-color: rgb(158, 102, 56);
    width: 100%;
}

.home-1012 {
    /* position: absolute; */
    height: 70%;
}

.foot {
    display: flex;
    flex-direction: column;
}

.foot2 {
    padding-top: 3rem;
    font-size: 14px;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.home11-nav0 {
    display: none;
}

/* ==============about css============== */


.concious_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem;
}

.concious_nav p {
    color: #000000;
    font-weight: 300;
    font-size: 13px;
    margin: 0px 25px;
    font-family: 'Roboto', sans-serif !important;
}

.concious_hr {
    border: 1px solid #D9D9D9 !important;
    width: 100%;
    display: block !important;
    margin: auto !important;
}

.concious_about {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 2.5rem 0rem;
    gap: 2rem;
}

.left_concious {
    width: 50%;
    /* margin-right: 30px; */
}

.right_concious {
    width: 50%;
    /* margin-left: 30px; */
}

.right_concious p {
    font-family: 'Roboto', sans-serif !important;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
}

.right_concious>p,
.right_concious>div>p {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.right_concious strong {
    font-family: 'Roboto', sans-serif !important;
}

.concious_img_flex {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    padding: 2rem 0rem;
}

.concious_img_flex img {
    width: 100%;
    max-width: 465px;
    object-fit: cover;
    /* margin: 0px 10px; */
}

.content1 {
    width: 50%;
}

.content2 {
    width: 50%;
}

.content1 p {
    color: #000000;
    /* width: 450px; */
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif !important;
    line-height: 24px;
    /* padding-right: 4rem; */
}

.content2 p {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    /* padding-left: 0.5rem; */
    font-family: 'Roboto', sans-serif !important;
}

.ek_hr {
    border: 1px solid #D9D9D9 !important;
    width: 100%;
}

.about-101 {
    padding: 8rem 4rem;
}

.home11-img .hero-img-1 {
    background-image: url('./assets/home/hero-1.svg');
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
}

.swiper-slide {
    transition-property: transform !important;
}

.hero-img-1 {
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    transition: 2s cubic-bezier(0.25, 0.1, 0.57, 0.83);
    transition-property: transform;
    transition-delay: 0.1s
}

.offline-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
}

.offline-message img {
    width: 5rem;
    height: 5rem;
}

.offline-message span {
    font-family: Helvetica, sans-serif !important;
    font-size: 1.8rem;
    letter-spacing: 0.01rem;
    font-weight: 600;
    color: red;
}

.offline-message p {
    font-family: Helvetica, sans-serif !important;
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(101, 101, 101);
}

/* .hero-img-1-active {background-image: url('./assets/home/hero-1.svg'); opacity: 1; transform: translate(0px, 0px);} */

@media(max-width: 1024px) {
    .home-1012 {
        left: 50%;
        transform: translate(-50%);
    }
}

@media screen and (max-width: 750px) {

    .home-1012 {
        left: 50%;
        transform: translate(-50%);
    }

    .home11-img .hero-img-1 {
        background-image: url('./assets/home/mobile-hero-1.svg');
        object-fit: cover;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 498px;
        width: 100%;
    }

    .home1111 h1 {
        width: 271px;
    }

    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 24px !important;
    }

    .home11122 {
        display: none;
    }

    .home111 {
        height: 55%;
        padding: 0rem 2rem;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
    }

    .home11123 {
        height: 30px;
        width: 30px;
        margin-left: 20px;
    }

    .home11-nav12 p {
        display: none;
    }

    .home11-nav13 {
        width: 100%;
    }

    .home3 {
        flex-direction: column;
    }

    .home312 {
        bottom: 290px;
    }

    .home4 {
        flex-direction: column;
    }

    .home41 {
        width: 100%;
        margin-bottom: 3rem;
    }

    .home42 {
        display: none;
    }

    .home421 {
        width: 70%;
        margin: auto;
    }

    .home422 {
        display: none;
    }

    .home5 {
        flex-direction: column;
        height: 100%;
    }

    .home51 {
        width: 100%;
        display: block;
        margin-right: 0;
    }

    .home52 {
        width: 100%;
        margin-left: 0;
    }

    .home612 {
        flex-direction: column;
    }

    .home612 img {
        width: 100%;
    }

    .home7 {
        padding: 15px;
        flex-direction: column;
    }

    .home71 {
        width: 100%;
    }

    .home72 {
        width: 100%;
        margin-top: 2rem;
    }

    .home72 img {
        width: 100%;
    }

    .home8 {
        padding: 2rem;
    }

    .home81 {
        flex-direction: column;
    }

    .home811 {
        margin-bottom: 2rem;
        width: 100%;
    }

    .home9 {
        padding: 60px 30px;
    }

    .home-10 {
        height: 100%;
        flex-direction: column;
    }

    .home-101 {
        width: 100%;
        padding: 80px 30px;
    }

    .home-102 {
        width: 100%;
    }

    .home-1011 {
        padding: 0 15px;
        margin-bottom: 2rem;
    }

    .home212 {
        display: none;
    }

    .home2 {
        padding: 40px 0 15px 15px;
    }

    .home2112 p {
        font-size: 13px;
    }

    .home2112 {
        flex-wrap: wrap;
    }

    .home11-img {
        height: 498px;

    }

    .home11-img img {
        height: 498px;
        width: 430px;
        object-fit: cover;
        background-position: center;
        background-size: cover;
    }

    .home11-nav {
        display: none;
    }

    .home11-nav0 {
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: space-between;
        top: 12px;
        left: 19px;
        border-radius: 8px;
        z-index: 99;
        background-color: white;
        padding: 27px 15px;
        width: 94%;
        margin: auto;
    }

    .concious_img_flex {
        flex-wrap: wrap;
        padding: 3rem 0;
        gap: 32px;
    }

    .concious_nav {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .concious_nav p {
        margin: 10px 20px;
    }

    .concious_about {
        flex-direction: column;
    }

    .left_concious {
        width: 100%;
        margin-right: 0px;
    }

    .right_concious {
        width: 100%;
        margin-left: 0px;
    }

    .right_concious {
        margin-top: 30px;
    }

    .about-10112 img {
        height: 250px;
        width: 100%;
        object-fit: cover;
    }

    .concious_img_flex img {
        /* margin: 15px 0px ; */
        width: 100%;
        max-width: 100%;
    }

    .img_content {
        flex-direction: column;
    }

    .content1 {
        margin-right: 0px;
        width: 100%;
    }

    .content2 {
        width: 100%;
        margin-left: 0px;
        margin-top: 15px;
    }
}

.new-in {
    padding: 80px 0 120px 40px;
    background-color: #FFF9F2;
}

@media(max-width: 768px) {
    .new-in {
        padding: 0 0 4rem 15px;
        background-color: #FFF9F2;
    }

    .content1 p {
        color: #000000;
        /* width: 340px; */
        font-size: 14px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif !important;
        line-height: 24px;
    }
}


.col-img {
    transform: scale(1);
    transition: all 0.8s;
    overflow: hidden;
}

.col-img:hover {
    transform: scale(1.3);
}

.home6 {
    padding: 0 4rem;
    background-color: #FFF9F2;
    position: relative;
}

.home7 {
    padding: 4rem;
    background-color: #FFF9F2;
}

.home-6 {
    width: 100%;
    position: relative;
}

.home-6 .img-overlay {
    position: absolute;
    background-color: #FFF;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    transition: transform 1s cubic-bezier(.25, 1, .5, 1);
    transform-origin: left
}

/* .home-6 .bar { right: 0;  position: absolute; background-color: #FFF; top: 0; left: 0; bottom: 0; transition: ease 0.7s; } */


@media(max-width: 1024px) {
    .home-101 {
        width: 100%;
    }

    .home-10111 {
        width: 100%;
    }

    .home-10112 {
        position: relative;
        bottom: 20px;
        width: 265px;
        height: 279px;
        right: 10%;
        transform: translate(50%, 0);
    }

    .home-1012 img {
        width: 223px;
        height: 396px;
    }

    .home-102 {
        display: none;
    }
}

.item-content ul {
    transition: all 0.5s;
}

@media(max-width: 769px) {
    .home3 {
        padding: 0;
    }

    .home4 {
        padding: 15px;
    }

    .home5 {
        padding: 0 15px;
        height: auto;
    }

    .home5111 {
        margin-bottom: 10px;
        height: 50%;
    }

    .home511 {
        margin: 0;
        margin-right: 10px;
    }

    .home512 {
        margin: 0;
    }

    .home5111 img {
        height: 201px;
        object-fit: cover;
        background-size: cover;
    }

    .home52 img {
        /* height: 201px; */
        object-fit: cover;
    }

    .home611 {
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        color: #000;
        text-align: left;
        display: unset;
    }

    .home6 {
        padding: 0;
    }

    .home611 h2 {
        color: #000;
        text-align: left;
        padding-top: 40px;
        padding-left: 1rem;
        font-size: 40px !important;
        font-weight: 200;
    }

    .home611 p {
        color: #000;
        text-align: left;
        padding-bottom: 27px;
        padding-left: 1rem;
        font-size: 15px;
    }

    .home7 {
        padding: 2rem 15px;
    }

    .home72 img {
        height: 242px;
        object-fit: cover;
        border-radius: 12px;
    }

    .home811 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home811 p {
        text-align: center;
        font-family: 'Goudy Old Style';
        font-weight: 400;
    }



    .home51111 h5 {
        font-size: 15px;
        margin-bottom: 0.2rem;
    }

    .home-101 {
        height: 945px;
        position: relative;
    }

    .home-102 {
        display: none;
    }

    .home-1012 img {
        width: 223px;
        height: 396px;
    }

    .home-1012 b {
        display: none;
    }

    .home-10112 {
        position: absolute;
        bottom: 20px;
        width: 265px;
        height: 279px;
        right: 50%;
        transform: translate(50%, 0);
    }

    .home-10111 p {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .home-10111 .text {
        font-size: 15px;
        margin-bottom: 10px;
        font-family: 'TheinhardtPan-Regular', sans-serif;
    }

    .home-10111 h1 {
        font-size: 50px !important;
    }

}

.customFont,
.customFont p,
.customFont h1,
.customFont h2,
.customFont h3,
.customFont h4,
.customFont h5,
.customFont h6,
.customFont strong,
.customFont b,
.customFont ins {
    font-family: 'Sorts Mill Goudy', serif !important;
}

.customFont {
    text-transform: unset !important;
    font-weight: 400 !important;
}

.customFont p {
    text-transform: unset !important;
}

@media(max-width: 497px) {
    .home52 img {
        width: 100%;
        height: 201px;
        object-fit: cover;
    }
}