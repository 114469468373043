@font-face {
  font-family: 'Saol';
  font-style: normal;
  font-weight: 400;
  src: local('Saol'), url('../../../font/saol_regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Goudy Old Style';
  font-style: normal;
  font-weight: 700;
  src: local('Goudy Old Style'), url('../../../font/Goudy\ Old\ Style\ Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: normal;
  src: local('Theinhardt Pan'), url('../../../font/theinhardt-pan.otf') format('otf');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: 300;
  src: local('Theinhardt Pan'), url('../../../font/theinhardt_pan_light.woff2') format('woff2');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: 500;
  src: local('Theinhardt Pan'), url('../../../font/theinhardt_pan_medium.woff2') format('woff2');
}

.customScrollbar::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 5px;
  border: none;
}

/* Handle on hover */
.customScrollbar::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.customScrollbar::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.customScrollbar::-webkit-scrollbar {
  width: 5px !important;
}


.cart-section {
  padding-top: 133px;
  background-color: #fff9f2;
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
}

.cart-section .cart-left {
  width: 66%;
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
}

/* 
.cart-section .cart-left::-webkit-scrollbar {
  display: none;
} */

.cart-left .cart-title {
  font-size: 100px;
  font-weight: 500;
  font-family: 'Theinhardt Pan', sans-serif;
  line-height: 90px;
  font-style: normal;
  margin-bottom: 40px;
}

.cart-section .cart-right {
  width: 36%;
  background-color: #FFf;
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 100px;
}


.cart-table {
  border: none;
  border-collapse: unset;
  border-spacing: 0;
  width: 100%;
}

.cart-table-thead tr {
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 40px;
  padding-bottom: 10px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0px;
  text-align: left;
}

.cart-table-thead tr th {
  color: #000;
  font-family: 'Goudy Old Style';
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
}

.cart-table-thead tr th:last-child {
  text-align: center;
}

.cart-table-tbody tr {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #D9D9D9;
  margin-top: 30px;
}

.cart-table-img {
  display: block;
  overflow: hidden;
  border: 1px solid #D9D9D9;
  height: 121px;
  width: 121px;
  background-color: #fff9f2;
}

.cart-table-img img {
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.cart-item-name {
  color: #000;
  font-weight: 800;
  font-size: 30px;
  font-family: 'Goudy Old Style';
  font-style: normal;
}

.cart-qnt-btn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid #000;
  padding: 2px 17px;
  /* padding: 17px; */
  width: 120px;
  justify-content: center;
}

.cart-qnt-btn button {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cart-qnt-btn input {
  width: 4rem;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  mix-blend-mode: darken;
  border: 0;
  outline: none;
  box-shadow: none;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.cart-qnt-btn input:focus,
.cart-qnt-btn input:hover {
  box-shadow: none;
  border: none;
}

.cart-qnt-btn input[type=number]::-webkit-inner-spin-button,
.cart-qnt-btn input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-qnt-btn .qnt-text {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cart-table-price {
  font-size: 23px;
  line-height: 1.1;
  font-weight: 400;
  font-family: 'Goudy Old Style';
  font-style: normal;
}

.free-ship-text {
  background-color: #a03907;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 60px;
}

.continue-shop {
  text-decoration: underline;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-style: normal;
  line-height: 20px;
}

.summary-title {
  background-color: #fff;
  padding-top: 34px;
  font-size: 50px;
  line-height: .9;
  font-family: 'Theinhardt Pan';
  font-weight: 500;
  margin-bottom: 48px;
}

.summary-text {
  font-size: 15px;
  line-height: 20px;
  font-family: 'Theinhardt Pan';
  font-weight: 300;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.cart-voucher {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
}

.coupon-input {
  border: 1px solid #D9D9D9;
  border-radius: 0;
  width: calc(100% - 60px);
  padding: 5px 10px;
  line-height: 20px;
  font-family: 'Theinhardt Pan';
  font-size: 15px;
  font-weight: 300;
}

.coupon-btn {
  border-radius: 0;
  padding: 5px;
  width: 60px;
  text-transform: uppercase;
  background-color: #000;
  color: #FFF;
  line-height: 20px;
  font-family: 'Theinhardt Pan';
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 300;
}

.order-btn {
  background-color: #000;
  color: #FFF !important;
  border-radius: 3px;
  display: flex;
  padding: 20px;
  gap: 8px;
  margin-top: 20px;
  transition: all 0.5s;
  border: 1px solid transparent;
}

.order-btn a {
  color: #FFF !important;
}

.order-btn:hover a {
  color: black !important;
}


.order-btn:hover p {
  color: black !important;
}

.order-btn .order-img {
  background-image: url('../../../assets/white-right-arrow.svg');
  height: 18px;
  width: 16px;
  background-size: cover;
  background-position: center;
  transition: all 0.5s;
}

.order-btn button {
  font-size: 19px;
  line-height: 20px;
  font-family: 'Theinhardt Pan';
  font-weight: 500;
  color: #FFF;
  text-transform: uppercase;
  transition: all 0.5s;
}

.order-btn:hover {
  background-color: #FFF;
  border: 1px solid #000;
}

.order-btn:hover .order-img {
  background-image: url('../../../assets/right-arrow.png');
}

.order-btn:hover button {
  color: #000;
}

.checkout-li li {
  position: relative;
  border-bottom: 1px solid #D9D9D9;
  font-family: 'Theinhardt Pan', sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 30px 0 20px 0;
  padding-left: 0px;
  font-size: 40px;
  line-height: 46px;
  text-transform: uppercase;
}

.checkout-num {
  width: 54px;
  height: 54px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 50%;
  /* margin-right: 10px; */
  display: inline-table;
  font-style: italic;
  font-family: "Saol";
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .checkout-li li:nth-child(1)::before { content: "1";}
.checkout-li li:nth-child(2)::before { content: "2";}
.checkout-li li:nth-child(3)::before { content: "3";}
.checkout-li li:nth-child(4)::before { content: "4";} */
.checkout-li li:last-child {
  border-bottom: none;
}

.checkout-content {
  padding-top: 60px;
  border-top: 1px solid #D9D9D9;
}

.identity_logged {
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 60px;
  background-color: #fff;
  font-size: 19px;
  line-height: 25px;
}

.identity_logged .login-name {
  color: #a03907;
  font-size: 19px;
  line-height: 25px;
  font-weight: 300;
  font-family: 'Theinhardt Pan';
}

.identity_logged .login-name a {
  color: #000;
  font-size: 19px;
  line-height: 25px;
  font-weight: 300;
  font-family: 'Theinhardt Pan';
}

.identity_logged .log-out {
  color: #000;
  font-size: 19px;
  line-height: 25px;
  font-weight: 300;
  font-family: 'Theinhardt Pan';
  margin-top: 14px;
}

.identity_logged .log-out a {
  color: #000;
  font-size: 19px;
  line-height: 25px;
  font-weight: 300;
  font-family: 'Theinhardt Pan';
  margin-top: 14px;
}

.form-text {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Theinhardt Pan';
  font-style: normal;
  margin-bottom: 90px;
}

.input-title {
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 300;
  font-family: 'Theinhardt Pan';
  font-style: normal;
  text-transform: uppercase;
}

.form-input {
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  max-width: 350px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  font-family: 'Theinhardt Pan';
  font-weight: 300;
}

.cart-item-list li {
  display: flex;
  margin: 10px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #D9D9D9;
  gap: 16px;
}

.cart-item-list .cart-media {
  width: 70px;
  border: 1px solid #D9D9D9;
  overflow: hidden;
}

.cart-item-list .media-name {
  font-weight: 500;
  font-family: 'Goudy Old Style';
  font-size: 19px;
  line-height: 19px;
  margin: 0;
}

.cart-item-list .media-qty {
  font-weight: 500;
  font-family: 'Theinhardt Pan';
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}

.cart-item-list .media-price {
  font-weight: 500;
  font-family: 'Goudy Old Style';
  font-size: 15px;
  line-height: 19px;
  margin: 0;
}


.address-card {
  width: 23rem;
  border: 1px solid #D9D9D9;
  padding: 20px;
  border-radius: 10px;
  font-size: 15px;
  line-height: 1.3;
  background-color: #FFF;
  transition: all 0.5s;
}

@media(max-width: 768px) {
  .checkout-li li {
    font-size: 28px;
    line-height: 30px;
  }
}

.address-container {
  display: flex;
  gap: 30px;
  flex-direction: row;
  margin-bottom: 30px;
  overflow-x: auto;
  overflow-y: hidden;
}

.address-card {
  width: 23rem;
  border: 1px solid #D9D9D9;
  padding: 20px;
  border-radius: 10px;
  font-size: 15px;
  line-height: 1.3;
  background-color: #FFF;
  transition: all 0.5s;
}

.address-card-select {
  border: 1px solid #a03907;
}

.address-card .address-title {
  font-size: 23px;
  line-height: 1.1;
  font-weight: 600;
  font-family: 'Goudy Old Style';
  text-transform: capitalize;
  margin-bottom: 20px;
}

.address-card .address-text {
  font-weight: 400;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  line-height: 19px;
  margin: 0;
}

.delivery {
  padding: 20px;
  background-color: #a03907;
  color: #fff;
  margin-bottom: 40px;
}

.delivery .delivery-title {
  font-style: italic;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Saol", sans-serif;
  margin-bottom: 20px;
}

.delivery .delivery-text {
  font-size: 15px;
  line-height: 1.3;
  font-family: "Theinhardt Pan", sans-serif;
  font-weight: 400;
}

.delivery .delivery-textarea {
  margin-top: 20px;
  border: 1px solid #fff;
  width: 100%;
  color: white !important;
  background-color: transparent;
  overflow: none;
  outline: none;
}

.delivery-option-header {
  border-bottom: 1px solid #D9D9D9 !important;
}

.option-header-item {
  text-transform: uppercase;
  font-family: 'Goudy Old Style';
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 1;
  padding-bottom: 20px;
}

.delivery-option {
  padding: 30px 0;
  border-bottom: 1px solid #D9D9D9;
}

.delivery-option .option-mode {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.3;
  font-family: "Theinhardt Pan", sans-serif;
  background-color: #fff9f2;
  color: #000;
  font-weight: 400;
}

.delivery-option .option-desc {
  font-size: 15px;
  line-height: 1.3;
  font-family: "Theinhardt Pan", sans-serif;
  background-color: #fff9f2;
  color: #000;
  font-weight: 400;
}

.pack-box {
  padding-top: 40px;
  padding-bottom: 30px;
}

.pack-title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  font-family: 'Theinhardt Pan', sans-serif;
  margin-bottom: 8px;
}

.pack-text {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  font-family: 'Theinhardt Pan', sans-serif;
  margin-bottom: 16px;
}

.pack-img {
  width: 80px;
  height: 80px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  overflow: hidden;
  display: block;
  object-fit: cover;
}

.packing-text {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  font-family: 'Theinhardt Pan', sans-serif;
}

.payment-box {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #D9D9D9;
}

.payment-text {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  font-family: 'Theinhardt Pan', sans-serif;
}

.payment-border {
  border-bottom: 1px solid #D9D9D9;
}

.payment-check-box {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  background-color: #fff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Theinhardt Pan', sans-serif;
}

.payment-check-box a {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Theinhardt Pan', sans-serif;
  text-decoration: underline;
}

.place-order-btn {
  font-size: 23px;
  line-height: 1.1;
  font-weight: 400;
  font-family: "Theinhardt Pan", sans-serif;
  position: relative;
  background-color: #000;
  padding: 20px 40px;
  color: #fff;
  border: 1px solid #000;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 30px;
  transition: all 0.5s;
}

.place-order-btn:hover {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}