@font-face {
  font-family: 'Saol';
  font-style: normal;
  font-weight: 400;
  src: local('Saol'), url('../../font/saol_regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Goudy Old Style';
  font-style: normal;
  font-weight: 700;
  src: local('Goudy Old Style'), url('../../font/Goudy\ Old\ Style\ Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: normal;
  src: local('Theinhardt Pan'), url('../../font/theinhardt-pan.otf') format('otf');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: 300;
  src: local('Theinhardt Pan'), url('../../font/theinhardt_pan_light.woff2') format('woff2');
}

@font-face {
  font-family: 'Theinhardt Pan';
  font-style: normal;
  font-weight: 500;
  src: local('Theinhardt Pan'), url('../../font/theinhardt_pan_medium.woff2') format('woff2');
}



.sub-header {
  /* width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 555;
  color: white;
  display: flex;
  border-bottom: 1px solid #D9D9D9;
  transition: all 0.5s; */
}

.sub-item {
  /* display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%; */
}


.sub-header-profile-img {
  position: relative;
}

/* .online-user {
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
  background-color: rgb(70, 211, 70);
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
  cursor: pointer;
  outline: 1.5px solid rgb(183, 179, 179);
  outline-offset: 1.5px;
  animation: dotRings 1s linear infinite forwards;
} */

/* .offline-user {
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
  background-color: rgb(70, 211, 70);
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  cursor: pointer;
  outline: 1px solid rgb(183, 179, 179);
  outline-offset: 1px;
  animation: dotRings 1s linear infinite forwards;
} */

/* @keyframes dotRings {

  0%,
  100% {
    outline: 1px solid rgb(183, 179, 179);
    outline-offset: 1.5px;
  }

  50% {
    outline: 2px solid rgb(183, 179, 179);
    outline-offset: 3px;
  }
} */

.sub-item .sub-header-text {
  color: #FFF;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 100%;
  padding: 20px 15px 13px;
  border-left: 0.5px solid #D9D9D9;
  border-right: 0.5px solid #D9D9D9;
  text-align: center;
}

.language {
  margin-right: 10px;
  padding: 20px 0px 18px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  /* border-left: 1px solid #d9d9d9; */
}

.language .language-text {
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 200;
  line-height: 19.5px;
  cursor: pointer;
}

.language .language-text- {
  color: #000;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 200;
  line-height: 19.5px;
  cursor: pointer;
}

.language .language-list {
  position: absolute;
  display: none;
  top: 58.5px;
  background: #fff9f2;
  padding: 10px 20px;
  right: -1px;
  width: calc(100% + 2px);
  text-align: center;
  border: 1px solid #D9D9D9;
  transition: all 0.5s;
  z-index: 100;
}

.language .language-list2 {
  position: absolute;
  display: none;
  top: -90px;
  background: #fff9f2;
  padding: 10px 20px;
  right: -1px;
  width: calc(100% + 2px);
  text-align: center;
  border: 1px solid #D9D9D9;
  transition: all 0.5s;
  z-index: 100;
  color: #000;
}

.language:hover .language-list {
  display: block;
}

.language:hover .language-list2 {
  display: block;
}

.language .language-list li {
  padding: 2px 0;

  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 200;
  line-height: 19.5px;
  color: #000;
  cursor: pointer;
}

.currency {
  padding: 20px 0px 18px;
  display: flex;
  align-items: center;
  padding-right: 30px;
  /* border-right: 1px solid #d9d9d9; */
}


.currency .currency-text {
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 19.5px;
  cursor: pointer;
}

.currency .currency-text- {
  color: #000;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 19.5px;
  cursor: pointer;
}

.currency .currency-list {
  position: absolute;
  display: none;
  top: 57.5px;
  background: #fff9f2;
  padding: 10px 20px;
  right: -1px;
  width: calc(100% + 2px);
  text-align: center;
  border: 1px solid #D9D9D9;
  transition: all 0.5s;
  z-index: 100;
}

.currency .currency-list2 {
  position: absolute;
  display: none;
  top: -90px;
  background: #fff9f2;
  padding: 10px 20px;
  right: -1px;
  width: calc(100% + 2px);
  text-align: center;
  border: 1px solid #D9D9D9;
  color: #000;
  transition: all 0.5s;
  z-index: 100;
}

.currency:hover .currency-list {
  display: block;
}

.currency:hover .currency-list2 {
  display: block;
}

.currency .currency-list li {
  text-transform: uppercase;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 200;
  line-height: 19.5px;
  color: #000;
  cursor: pointer;
}

.sub-item .sub-header-text b {
  color: #FFF;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sub-item .sub-items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 0rem 2rem;
  justify-content: center;
  mix-blend-mode: darken;
}

.search-icon {
  font-size: 1.15rem !important;
}

.my-account-icon {
  font-size: 1.25rem !important;
}

.wishlist-icon {
  font-size: 1.25rem !important;
}


.header-cart-btn h2 {
  position: relative;
  font-family: Theinhardt Pan, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  /* margin-bottom: 0.3rem; */
}

.header-cart-btn svg {
  font-size: 1.3rem !important;
}

.cart-qty-circle-home {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.2rem;
  right: 1.7rem;
  min-width: 23px;
  min-height: 23px;
  border-radius: 50%;
  background-color: #a03907;
  color: #fff;
}

.mobile-cart-qty-circle-home {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1.2rem;
  right: -0.3rem;
  min-width: 23px;
  min-height: 23px;
  border-radius: 50%;
  background-color: #a03907;
  color: #fff;
}

.cart-qty-circle-home span,
.mobile-cart-qty-circle-home span {
  font-family: 'Theinhardt Pan', sans-serif !important;
  font-size: 0.7rem !important;
  padding: 0.4rem 0.4rem;
  line-height: 0.7rem;
}

#subheader-icon img {
  mix-blend-mode: difference;
}

#subheader-icon .icon {
  color: #fff;
  font-size: 20px;
  /* White icon color */
}

#subheader-cart-image img {
  mix-blend-mode: darken;
}

.sub-item .sub-items img {
  width: 1.2rem;
  min-width: 1rem;
  height: auto;
  color: #000;
}

/* Icon container */
.sub-items {
  display: flex;
  align-items: center;
  color: #000;
}

/* Icon button/link */
.icon-button,
.icon-link {
  /* background-color: #fff; Background color */
  border: none;
  /* Adjust padding */
  /* Adjust spacing between icons */

  /* Add shadow */
  transition: all 0.3s ease;
  /* Smooth transition */
}

/* Icon */
.icon {
  font-size: 21px;
  /* Adjust icon size */
  color: #000;
  /* Icon color */
}

/* Hover effect */
.icon-button:hover,
.icon-link:hover {
  /* transform: translateY(-2px); Move up slightly */
  box-shadow: 0 rgba(0, 0, 0, 0.2);
  /* Increase shadow */
}


.header-cart-btn {
  width: 100%;
  padding: 14px 3rem;
  border-left: 0.5px solid #D9D9D9;
  color: #FFF;
  font-family: 'Theinhardt Pan', sans-serif;
  display: flex;
  gap: 0.4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.cart-btn {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 14px 0;
  border-left: 0.5px solid #D9D9D9;
  color: #FFF;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 100%;
  justify-content: center;
}

.cart-btn- {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 14px 0;
  border-left: 0.5px solid #D9D9D9;
  color: #000;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  justify-content: center;
}


.marquee {
  padding: 17px 0 13px 40px;
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  align-items: center;
}

.mar-an {
  /* width: 50%; */
  display: flex;
}

.bar_content {
  display: inline-block;
  width: 100%;
  background-color: #a03907;
  transform: translateX(10%);
  animation: move 25s linear infinite;
  color: white;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-weight: 300;
}

.bar_content- {
  display: inline-block;
  width: 100%;
  transform: translateX(10%);
  animation: move 25s linear infinite;
  color: white;
  font-family: "Theinhardt Pan", sans-serif;
  font-size: 15px;
  font-weight: 300;
}

@keyframes move {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.reel-text-container {
  color: white;
  font-family: "Theinhardt Pan", sans-serif;
  font-size: 15px;
  font-weight: 300;
}

.marquee-ele {
  display: flex;
  justify-content: start;
  overflow: hidden;
  gap: 20px;
  width: 100%;
}

.marquee-ele span {
  white-space: nowrap;
  animation: movertl 30s  infinite;
}

@keyframes movertl {
  0% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(-110%);
  }

}

.visible {
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden {
  top: -80px;
  transition: top 0.4s ease-out;
}


@media(max-width: 480px) {
  .sub-header {
    display: none;
  }
}

/* header { position: fixed; padding: 0; width: 100%; z-index: 500; transition: all 0.5s;} */
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  /* background-color: #fff9f2; */
}

.header-box .logo,
.header-box .logo-home {
  background-image: url('../../assets/account/logo.svg');
  height: 46px;
  width: 178px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 15px 0;
}

.header-box .white-logo {
  background-image: url('../../assets/account/logo-white.svg');
  height: 46px;
  width: 178px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 15px 0;
}

header .header-item {
  display: flex;
  gap: 32px;
}

header:hover .header-item {
  color: #000 !important;
}

.header-item .header-list .a-tag {
  text-decoration: none;
  color: #FFF;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 30px;
}

.header-item .header-list .eshop:hover::after {
  position: absolute;
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background-color: #000;
  bottom: 0;
}

.header-item .header-list .inspiration:hover::after {
  position: absolute;
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background-color: #000;
  bottom: 0;
}

.header-item .header-list {
  text-decoration: none;
  color: #FFF;
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bras-btn {
  display: none;
}

.close-icon {
  position: relative;
  padding: 27px 25px 0 25px;
}

.close-icon:before {
  position: absolute;
  content: "";
  background-color: #FFF;
  height: 2px;
  width: 20px;
  right: 0;
  transform: rotate(45deg);
  top: 35px;
}

.close-icon:after {
  position: absolute;
  content: "";
  background-color: #FFF;
  height: 2px;
  width: 20px;
  right: 0;
  transform: rotate(-45deg);
  top: 35px;
}

header .overlay {
  background-color: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 990;
  transition: ease 0.5s;
  overscroll-behavior: contain;
}

.desktop-header {
  display: block;
  position: fixed;
  padding: 0;
  width: 100%;
  z-index: 500;
  transition: all 0.5s;
  /* top: 60px !important; */
  border-bottom: 1px solid #D9D9D9;
  overscroll-behavior: contain;
}

.mobile-header {
  display: none;
}


.responsive-cart {
  display: none;
}

.header-item li .e-shop-container {
  position: absolute;
  top: 76px;
  left: 0;
  width: 100%;
  opacity: 0;
  padding-top: 0px;
  z-index: -1;
  background-color: #fff;
  transform: translateY(-100%);
  -webkit-transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  -moz-transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  -ms-transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  -o-transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  /* transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s; */
}



.header-item li:hover .e-shop-container {
  transform: translateY(0);
  opacity: 1;
}

.e-shop-body {
  max-width: 1920px;
  max-height: 27rem;

  color: black !important;
  width: 100%;
  opacity: 1 !important;
  display: flex;
  flex-direction: row;
  -webkit-transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  -moz-transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  -ms-transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  -o-transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  transition: all 0.7s cubic-bezier(.25, 1, .5, 1);
  font-family: 'Inter', sans-serif !important;
}


.e-shop-header-category-grid {
  min-width: 22rem;

  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* gap: 1.5rem; */
  gap: 0.5rem;
  padding-bottom: 3.5rem;
  padding-top: 3.5rem;
  padding-left: 2rem;

  /* overflow-y: auto; */
}

.e-shop-body-categroy {
  width: min-content;
  text-wrap: nowrap;
}

/* .grid-section-1 {
  grid-column: 1/2;
  grid-row: 1/2;
}

.grid-section-2 {
  grid-column: 1/2;
  grid-row: 2/-1;
}

.grid-section-3 {
  grid-column: 2/-1;
  grid-row: 1/2;
}

.grid-section-4 {
  grid-column: 2/-1;
  grid-row: 2/-1;
} */

.e-shop-body .list {
  min-width: 350px;
  max-width: max-content;
  width: 25%;
  padding: 60px 0 60px 20px;
}

.categories-list li {
  display: block;
  color: #000;
}

.categories-list li span div span {
  white-space: nowrap;
}

.categories-list li div {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.categories-list li div span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.headerListItems {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  display: block;
  color: #000;
  font-size: 0.9rem
}

.header-sub-header {
  font-size: 14px;
  color: #000 !important;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 10px;
  transition: all 0.6s;
}


.categories-list li a {
  font-size: 14px;
  color: #000 !important;
  font-weight: 400;
  line-height: 20px;
  font-family: 'TheinhardtPan-Regular', sans-serif;
  /* margin-bottom: 20px; */
  transition: all 0.6s;
}

.categories-list li a:hover {
  border-bottom: 1px solid #000;
}


.header-products {
  width: 100%;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  /* padding: 60px 60px; */
  padding: 60px 2rem;
}

.header-img {
  display: block;
  height: 166px;
  width: 100%;
  overflow: hidden;
}

.header-img img {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  background-position: center;
  object-fit: cover;
  transform: scale(1);
}

.header-img img:hover {
  transform: scale(1.3);
}

.inspire-list {
  padding: 60px;
}

.inspire-title {
  font-family: 'Saol';
  font-size: 30px;
  font-weight: 400;
  font-style: italic;
  line-height: 36px;
}

.search-box {
  display: block;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0 31px;
  background-color: rgba(160, 57, 7, .9);
  z-index: 1000;
  transition: ease 0.5s;
}

.close-img {
  right: 80px;
  position: absolute;
  top: 80px;
}

.close-img img {
  height: 50px;
  width: 50px;
}

.right {
  width: 50px;
}

.right img {
  height: 50px;
  width: 50px;
}

.search-input {
  font-size: 96px;
  font-family: "Saol";
  font-weight: 400;
  background-color: transparent;
  color: #fff;
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
}

.search-input[type='text']:focus {
  --tw-ring-color: transparent;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #FFF;
}

.search-input::placeholder {
  color: #fff;
}

.search-item-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-sub-header-mobile {
  color: #fff;
  font-family: Goudy Old Style;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 10px;
  text-transform: uppercase;
}

.header-sub-header-mobile a {
  color: #fff;
  font-family: 'Goudy Old Style';
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.header-products p strong {
  font-family: TheinhardtPan-Regular, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.cart-sidebar {
  display: block;
  position: fixed;
  min-width: 500px;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(160, 57, 7, .9);
  z-index: 1000;
  transition: ease 0.5s;
  background-color: #fff9f2;
  border-left: 1px solid #D9D9D9;
  padding-top: 100px;
}

.cart-title {
  font-family: 'Saol';
  font-style: italic;
  font-weight: 200;
  font-size: 40px;
  line-height: 46px;
  text-transform: uppercase;
  position: relative;
}

.cart-title span {
  position: absolute;
  top: 5px;
  right: -44px;
  background-color: #a03907;
  color: #fff;
  padding: 4px 3px 3px 3px;
  font-size: 15px;
  line-height: 21px;
  width: 28px;
  text-align: center;
  border-radius: 50%;
  font-family: 'Saol';
  font-style: italic;
}

.cart-close {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  text-transform: uppercase;
  font-family: 'Theinhardt Pan', sans-serif;
}

.cart-top-item {
  padding: 0 40px;
}

/* .cart-bottom-item {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  min-width: 500px;
} */

.cart-offer-text {
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.5px;
  font-family: 'Goudy Old Style', sans-serif;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
}

.cart-proceed-box {
  padding: 40px;
}

.cart-label-left {
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
  margin-bottom: 6px;
  float: left;
}

.cart-label-right {
  font-family: 'Theinhardt Pan', sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
  margin-bottom: 6px;
  float: right;
}



@media(max-width: 1024px) {
  .sub-header-container {
    display: none !important;
  }

  .mobile-header {
    display: block;
    padding: 10px;
    position: fixed;
    z-index: 500;
    width: 100%;
    transition: all 0.5s ease-in-out;
    scroll-behavior: smooth;
    top: 0px;
  }

  .desktop-header {
    display: none;
  }

  .bras-btn {
    display: block;
    border: none;
    background-color: transparent;
  }

  .header-box {
    border-bottom: none;
    background-color: #FFF;
    border-radius: 10px;
    padding: 5px 25px;
  }

  /* .header-box .logo {
    background-image: url('../../../public/images/p32.png');
    height: 21px;
    width: 82px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
    margin-left: 0;
  } */


  header .header-item {
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    padding: 0 31px;
    background-color: #000;
    z-index: 1000;
    transition: ease 0.5s;
  }

  .header-item .header-list {
    display: flex;
    padding-right: 20px;
    bottom: 60px;
    padding-bottom: 50px;
    overflow: auto;
    margin-top: 0px;
    flex-wrap: wrap;
    height: 100% !important;
    position: absolute;
    max-height: calc(var(--vh, 1vh) * 100 - 140px);
    overflow: auto;
    height: auto !important;
  }

  .header-list .mobile-item {
    display: block;
    overflow: hidden;
    width: 100%;

  }

  .header-list a {
    font-size: 36px;
    font-family: 'Goudy Old Style';
    font-weight: 400;
    padding: 5px 0;
    display: block;
    transform: translateY(100%);
    transition: 1s cubic-bezier(0.25, 0.1, 0.57, 0.83);
    transition-property: transform;
  }

  .responsive-cart {
    display: flex;
    color: #FFF;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #D9D9D9;
    padding: 10px 0;
    width: 85.5%;
    align-items: center;
    background-color: #000;
  }

  .responsive-cart .lang-text {
    padding: 0 40px 0 65px;
    text-transform: capitalize;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 14px;
  }

  .responsive-cart .currency-name {
    padding: 0 10px 0 0px;
    text-transform: uppercase;
    font-family: 'TheinhardtPan-Regular', sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .search-input {
    font-size: 50px;
  }

  .e-shop-body {
    flex-direction: column;
    gap: 1.5rem;
  }

  .e-shop-body .e-shop-body-categroy {
    display: flex;
    flex-direction: column;
    /* gap: 0.8rem; */
  }

  .e-shop-body-categroy>ul {
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: 0.3rem;
  }

  .e-shop-body a {
    display: block;
    color: #fff;
    font-family: 'Goudy Old Style';
    font-size: 13px;
    font-weight: 500;
    /* text-transform: uppercase; */
  }

  .categories-list li {
    display: block;
    color: #fff;
  }

  .categories-list li a {
    color: #fff !important;
    font-family: TheinhardtPan-Regular, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    padding: 0 0 4px;
    transform: none;
    transition: all .6s;
    text-decoration: none !important;
  }

  .cart-sidebar {
    min-width: 100%;
  }
}



@media(max-width: 768px) {


  .close-img {
    right: 10px;
    position: absolute;
    top: 10px;
  }

  .right {
    width: 24px;
  }

  .right img {
    height: 26px;
    width: 26px;
  }

  .search-input {
    font-size: 22px;
  }

  .e-shop-body .list {
    padding: 0;
  }

  .categories-list {
    /* margin-bottom: 20px; */
  }



  /* .cart-bottom-item {
    min-width: 100%;
  } */



  .header-list .mobile-item {
    color: white;
  }
}


.showHeaderChild {
  animation: 1s ease-in-out fadeHeaderChild linear;
}

.customScrollbar::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 5px;
  border: none;
}

/* Handle on hover */
.customScrollbar::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.customScrollbar::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.customScrollbar::-webkit-scrollbar {
  width: 10px !important;
}

.customizeScrollbar::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 5px;
  border: none;
}

/* Handle on hover */
.customizeScrollbar::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.customizeScrollbar::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.customizeScrollbar::-webkit-scrollbar {
  width: 5px !important;
}

.categories-list a {
  text-decoration: none;
  border: none;
}

.categories-list a:hover {
  border: none;
  text-decoration: none !important;
}

@keyframes fadeHeaderChild {
  0% {
    opacity: 0;
    top: 50px;
  }

  100% {
    opacity: 1;
    top: 15px;
  }
}